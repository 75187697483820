import {CountUp} from 'countup.js'

function Counter(initialValue) {
    return {
        counter: null,
        value: initialValue ? initialValue : 0,
        init() {
            const options = {
                duration: 1,
                separator: "'"
            }
            this.counter = new CountUp(this.$refs.counter,this.value,options)
            if (!this.counter.error) {
                this.counter.start();
            } else {
                console.error(this.counter.error);
            }
        },
        count(value,add = false) {
            if(add) {
                this.value = this.value + value
            } else {
                this.value = value
            }
            this.counter.update(this.value)
        }
    }
}

window.Counter = Counter;