import Quill from 'quill';

const Module = Quill.import('core/module');

class CardEditableModule extends Module {
    constructor(quill, options) {
        super(quill, options);
        this.quill = quill;
        this.options = options;

        let listener = (e) => {
            if (!document.body.contains(quill.root)) {
                return document.body.removeEventListener('click', listener);
            }
            let elm = e.target.closest('figure');
            let deselectCard = () => {
                if (elm.__onDeselect) {
                    elm.__onDeselect(quill);
                } else {
                    quill.setSelection(quill.getIndex(elm.__blot.blot) + 1, 0, Quill.sources.USER);
                }
            }
            if (elm && elm.__blot && elm.__onSelect) {
                quill.disable();
                elm.__onSelect(quill,e.target);
                let handleKeyPress = (e) => {
                    if (e.keyCode === 27 || e.keyCode === 13) {
                        window.removeEventListener('keypress', handleKeyPress);
                        quill.enable(true);
                        deselectCard();
                    }
                }
                let handleClick = (e) => {
                    if (e.which === 1 && !elm.contains(e.target)) {
                        window.removeEventListener('click', handleClick);
                        quill.enable(true);
                        deselectCard();
                    }
                }
                window.addEventListener('keypress', handleKeyPress);
                window.addEventListener('click', handleClick);
            }
        };
        quill.emitter.listenDOM('click', document.body, listener);
    }

}

export default CardEditableModule