// another approach would be to use alpine for all of the answers and pass the answer data as JSON.
// -> answers live as models in alpine scope
// makes it easier to add / remove answers and to reorder them later
// x-init could be used to pass the initial data
// most of the DOM markup could still come from the rails template above

function MultipleChoiceAnswer() {

    var checkExplanation = (accumulated, current, index) => {
        if(accumulated && index) { return accumulated }
        return !!current.value;
    }

    return {
        removed: false,
        showExplanation: false,
        explanationRef: null,
        init(explanationRef) {
            this.explanationRef = explanationRef;
            this.showExplanation = Array.from(explanationRef.querySelectorAll('input')).reduce(checkExplanation, false);
        },
        displayExplanation() { this.showExplanation = true; return false;  },
        removeExplanation() {
            this.explanationRef.querySelectorAll('input').forEach((element) => element.value = null);
            this.showExplanation = false
        },
        isExplanationShowed() { return this.showExplanation },
        isShowed() { return !this.removed;},
        removeAnswer(answer) {
            answer.querySelector('input.destroy').value = "1";
            this.removed=true;
            this.$refs.container.innerHTML = "";
            //answer.hide();
            // answer.remove();
            this.$el.dispatchEvent(new CustomEvent('answer-removed',{ bubbles: true }))
        }
    }
}

window.MultipleChoiceAnswer = MultipleChoiceAnswer