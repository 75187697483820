function FormRepeaterField(association, template) {
    return {
        template: null,
        association: null,
        init() {
            if(!this.$el.dataset.association) {
                console.error('[data-association] attribute must be set on form repeater field')
                return
            }
            if(!this.$refs.template) {
                console.error('[x-ref="template"] not found within the form repeater field')
                return
            }
            this.association = this.$el.dataset.association;
            this.template = this.$refs.template.innerHTML
            this.$refs.template.innerHTML = "";
            this.checkFields();
        },
        // will be triggered by the 'quill-empty', 'quill-not-empty', 'answer-removed' custom events
        checkFields() {
            const children = this.$refs.container.querySelectorAll('input[name*="content"]');
            const emptyCount = Array.from(children).filter((elem) => elem.value === "").length;
            if(emptyCount === 0) {
                this.insertField()
            }
        },
        // deprecated
        addField(event,container, association, content) {
            event.preventDefault();
            var new_id = new Date().getTime();
            var regexp = new RegExp("new_" + association, "g");
            container.insertAdjacentHTML('beforeend',content.replace(regexp,new_id))
            return false;
        },
        insertField() {
            var new_id = new Date().getTime();
            var regexp = new RegExp("new_" + this.association, "g");
            const content = this.template
            this.$refs.container.insertAdjacentHTML('beforeend',content.replace(regexp,new_id))
        }
    }
}
window.FormRepeaterField = FormRepeaterField;