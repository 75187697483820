function Favorite(lesson_id,isActive) {
    return {
        id: lesson_id,
        active: isActive,
        loading: false,
        fav() {
            if(this.loading) { return }
            this.loading = true;

            let success = (data) => {
                this.loading = false;
                this.active = !this.active
            }

            // TODO: error handling

            Rails.ajax({
                type: this.active ? 'DELETE' : 'POST',
                url: '/favorite',
                data: new URLSearchParams({ lesson_id: this.id }).toString(),
                success: success.bind(this)
            })
        }
    }
}
window.Favorite = Favorite;