import Block from 'quill/blots/block';

const ATTRIBUTES = [
    'class'
]

class HighlightBlot extends Block {
    static blotName = "highlight"
    static tagName = "div"
    static className = "special_block_yellow"

    static formats(domNode) {
        return ATTRIBUTES.reduce(function(formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
    }
    format(name, value) {
        // if name is found in attributes list, format is applied
        if (ATTRIBUTES.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}

export default HighlightBlot
