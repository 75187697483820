function TextArea() {
    return {
        init() {
            this.updateHeight()
            this.$el.addEventListener('input', this.updateHeight.bind(this))
            this.$el.addEventListener('cut', this.delayedUpdate.bind(this))
            this.$el.addEventListener('paste', this.delayedUpdate.bind(this))
            this.$el.addEventListener('drop', this.delayedUpdate.bind(this))
            this.$el.addEventListener('keydown', this.delayedUpdate.bind(this))
        },
        updateHeight() {
            this.$el.style.height = 'auto'
            this.$el.style.height = this.$el.scrollHeight +'px';
        },
        delayedUpdate() {
            window.setTimeout(this.updateHeight.bind(this), 0);
        }
    }
}
window.TextArea = TextArea;