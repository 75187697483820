import Quill from "quill";
import { BlockEmbed } from "quill/blots/block"


const ATTRIBUTES = [
    'alt',
    'height',
    'width',
    'class'
]

class ImageBlot extends BlockEmbed {
    static blotName = "image";
    static tagName = ["figure", "image"];

    static create(value) {
        let node = super.create();
        let img = window.document.createElement("img");
        if (value.alt || value.caption) {
            img.setAttribute("alt", value.alt || value.caption);
        }
        if (value.src || typeof value === "string") {
            img.setAttribute("src", value.src || value);
        }
        node.appendChild(img);
        if (value.caption) {
            let caption = window.document.createElement("figcaption");
            caption.innerHTML = value.caption;
            node.appendChild(caption);
        }
        return node;
    }

    constructor(node) {
        super(node);
        node.__onSelect = (quill,target) => {
            let caption = node.parentElement.querySelector("figcaption");
            let captionInput = window.document.createElement("input");
            captionInput.placeholder = "Bildunterschrift (optional)";
            if(caption) {
                let existingInput = caption.querySelector("input")
                if(!existingInput) {
                    captionInput.value = caption.innerText;
                    caption.innerHTML = "";
                    caption.appendChild(captionInput);
                } else {
                    if(target.tagName && target.tagName.toUpperCase() === "FIGCAPTION") {
                        existingInput.focus();
                    }
                }
            } else {
                caption = window.document.createElement("figcaption");
                caption.appendChild(captionInput);
                node.appendChild(caption);
            }
        };
        node.__onDeselect = () => {
            const caption = node.querySelector("figcaption");
            if(!caption) {
                return
            }
            const captionInput = node.querySelector("input")
            if(captionInput) {
                const value = captionInput.value;
                if (!value || value === "") {
                    caption.remove();
                } else {
                    caption.innerText = value;
                }
                captionInput.remove();
            }
        }
    }

    static value(node) {
        let img = node.querySelector("img");
        let figcaption = node.querySelector("figcaption");
        if (!img) return false;
        return {
            alt: img.getAttribute("alt"),
            src: img.getAttribute("src"),
            caption: figcaption ? figcaption.innerText : null
        };
    }

    static formats(domNode) {
        return ATTRIBUTES.reduce(function(formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
    }

    format(name, value) {
        if (ATTRIBUTES.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value);
            } else {
                this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}

export default ImageBlot