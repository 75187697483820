function Player() {
    return {
        currentExercise: 0,
        exercises: window.exercises,
        visibleExercises: [],
        validations: new Map(),
        correctCount: 0,
        wrongCount: 0,
        totalCount: 0,
        startDate: new Date(),
        points: 0,
        totalDuration: null,
        exerciseDuration: null,
        state: 'play', // 'play', 'validated', 'done', 'flashcard'
        // called when a new exercise is initialized
        init() {
            this.visibleExercises.push(this.exercises[this.currentExercise])
            this.totalCount = this.exercises.length
            this.newValidation = this.newValidation.bind(this)
            this.repeatAll = this.repeatAll.bind(this)
            this.repeatWrong = this.repeatWrong.bind(this)
            this.exerciseDuration = new Date();
            window.addEventListener('validated', this.newValidation)
            if(this.exercises[this.currentExercise].type === 'Flashcard') {
                this.state = 'flashcard'
            } else {
                this.state = 'play'
            }
        },
        submitValidation(validation) {
            if(window.progressURL) {
                fetch(`${window.progressURL}?id=${validation.id}&correct=${validation.correct}&duration=${validation.duration}`)
                    .then(response => response.json())
                    .catch((error) => {
                        // TODO: deal with connection interruptions / other potential issues (user was logged out)
                        console.error('Error:', error);
                    });
            }
        },
        newValidation(event) {
            const points = event.detail.validation.points
            const validation = event.detail.validation;
            const nextExercise = event.detail.nextExercise;

            this.points = this.points + points
            if(points) {
                window.dispatchEvent(new CustomEvent('points', { detail: { value: points, add: true}}))
            }
            // calculates the duration in milliseconds between the start / end of the exercise in seconds
            validation.duration =  Math.abs((new Date().getTime() - this.exerciseDuration.getTime()));

            this.submitValidation(validation)
            this.validations.set(validation.id,validation);
            if(validation.correct) {
                this.correctCount++
            } else {
                this.wrongCount++
            }

            this.validated();
            if(nextExercise) {
                this.nextExercise()
            }
        },
        validated() {
            this.state = 'validated'
            this.$nextTick(() => {
                if(this.$refs.nextButton) {
                    this.$refs.nextButton.focus()
                }
            })
            window.removeEventListener('validated', this.newValidation)
        },
        nextExercise() {
            if(this.currentExercise === this.exercises.length-1) {
                this.done()
                return;
            }
            this.currentExercise++;
            this.init()
        },
        done() {
            window.removeEventListener('validated',this.validate)
            window.addEventListener('player-repeat-all', this.repeatAll)
            window.addEventListener('player-repeat-wrong',this.repeatWrong)
            this.state = 'done'
            this.totalDuration = intervalToDuration({
                start: this.startDate,
                end: new Date()
            })
            this.$nextTick(() => {
                this.$refs.done.scrollIntoView()
                if(this.$refs.repeatButton) {
                    this.$refs.repeatButton.focus()
                }
            })

        },
        validate() {
            const exercise = this.getExercise(this.currentExercise)
            switch(exercise.type) {
                case "Flashcard":
                    break;
                case "MultipleChoice":
                    window.dispatchEvent(new CustomEvent('validate-multiple-choice', { detail: { exercise }}))
                    break;
                default:
            }
        },
        getExercise(index) {
            return this.exercises[index]
        },
        getExerciseById(id) {
            return this.exercises.find((e) => e.id === id)
        },
        flipFlashcard() {
            window.dispatchEvent(new CustomEvent('flip-flashcard'))
        },
        validateFlashcardCorrect() {
            window.dispatchEvent(new CustomEvent('validate-flashcard-correct'))
        },
        validateFlashcardWrong() {
            window.dispatchEvent(new CustomEvent('validate-flashcard-wrong'))
        },
        repeat(exercises) {
            window.removeEventListener('player-repeat-all', this.repeatAll)
            window.removeEventListener('player-repeat-wrong',this.repeatWrong)
            // maybe shuffle them here?
            window.exercises = exercises
            this.exercises = exercises
            this.currentExercise = 0
            this.points = 0
            this.exercises = window.exercises
            this.visibleExercises = []
            this.validations = new Map()
            this.correctCount = 0
            this.wrongCount = 0
            this.startDate = new Date()
            this.totalDuration = null
            window.dispatchEvent(new CustomEvent('points', { detail: { value: 0}}))
            this.init()
        },
        repeatAll() {
            this.repeat(this.exercises)
        },
        repeatWrong() {
            this.repeat(Array.from(this.validations.values())
                .filter((e) => !e.correct)
                .map((e) => this.getExerciseById(e.id))
            )
        }

    }
}
window.Player = Player