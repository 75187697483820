function AddExercise() {
    return {
        isOpen: false,
        addExercise(event) {
            this.isOpen = false

            // get indexes from current / next turboframe
            let currentIndex = null
            let nextIndex = null
            let nextFrame = null

            const currentFrame = this.$el.closest('turbo-frame')
            if(currentFrame) {
                currentIndex = currentFrame.dataset.index
                nextFrame = currentFrame.nextSibling
            }
            if(nextFrame && nextFrame.dataset) {
                nextIndex = nextFrame.dataset.index
            }

            // create turbo frame
            const id = "new_exercise"

            // find existing form and change ID to prevent conflicts and allow multiple forms to be open at same time
            const existingForm = document.querySelector("turbo-frame#" + id)
            if(existingForm ) {
                if(existingForm.getAttribute('busy')) {
                    // wait until existing form has finished loading
                }
                existingForm.setAttribute('id', "new_exercise_" + Date.now())
            }

            let frame = document.createElement("turbo-frame")
            const frameSource = event.target.href;
            frame.innerHTML = "<div class='flex flex-1 items-center justify-items-center p-2'><i class='mx-auto fa fa-spinner fa-spin fa-lg text-gray-300'></i></div>"
            let queryVars = {}
            if (currentIndex !== null) { queryVars['current_index'] = currentIndex}
            if (nextIndex !== null) { queryVars['next_index'] = nextIndex}
            let params = new URLSearchParams(queryVars)
            frame.setAttribute('src', frameSource + "?" + params.toString());
            frame.setAttribute('id', id)

            currentFrame.insertAdjacentElement('afterend',frame)

        }
    }
}
window.AddExercise = AddExercise