function SwitchField() {
    return {
        checked: false,
        checkboxRef: null,
        init(checkboxRef) {
            this.checkboxRef = checkboxRef;
            this.checked = checkboxRef.checked;
        },
        check() {
            this.checked = !this.checked;
            this.checkboxRef.checked = this.checked;
        },
        keydown(event) {
            if(event.keyCode === 32 || event.key === " ") {
                this.check();
                return true;
            }
            return false;
        },
        trigger: {
            ['@click']() {
                this.check()
            },
            ['x-on:keydown']($event) {
                this.keydown($event);
            }
        }
    }
}
window.SwitchField = SwitchField;