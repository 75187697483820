import Chartist from 'chartist'

function Donutchart(options = null) {
    const default_options = {
        donut: true,
        donutWidth: 32,
        startAngle: 0,
        showLabel: false
    }

    return {
        init() {
            const chart_data = JSON.parse(this.$el.dataset.chart)
            new Chartist.Pie(this.$el, chart_data, {...default_options, ...options});
        }
    }
}
window.Donutchart = Donutchart