import Quill, {quillDefaults, quillCompact} from "./quill";
import debounce from "./helpers/debounce";

function QuillField() {

    // removes the empty line that quill automatically adds to the editor
    // https://github.com/quilljs/quill/issues/1235
    const removeEmptyTagsAtEnd = (value) => {
        var emptyHtml = "<p><br></p>";
        var emptyTags = value.substring(value.length-emptyHtml.length);
        if(emptyTags === emptyHtml) {
            value = value.substring(0,value.length-emptyHtml.length)
        }
        return value;
    }

    const getSiblings = (elem) => {
        // Setup siblings array and get the first sibling
        var siblings = [];
        var sibling = elem.parentNode.firstChild;

        // Loop through each sibling and push to the array
        while (sibling) {
            if (sibling.nodeType === 1 && sibling !== elem) {
                siblings.push(sibling);
            }
            sibling = sibling.nextSibling
        }
        return siblings;
    };

    return {
        quill: null,
        globalToolbarContainer: null,
        toolbar: null,
        selectionChange(selection) {
            if(selection == null) {
                const shownToolbars = this.globalToolbarContainer.querySelectorAll('.ql-toolbar:not(.hidden)').length
                if(shownToolbars > 1) {
                    this.hideToolbar(this.toolbar.container)
                }
            } else {
                this.showToolbar(this.toolbar.container)
            }
        },
        hideToolbar(toolbarContainer) {
            toolbarContainer.classList.add('hidden')
        },
        showToolbar(toolbarContainer) {
            if(toolbarContainer) {
                // hide all other toolbars
                const siblings = getSiblings(toolbarContainer).map((elem) => elem.classList.add('hidden'))
                // show current toolbar
                toolbarContainer.classList.remove('hidden')
            }
        },
        init(element) {
            const originalInput = element.nextSibling;
            let options = quillDefaults;

            const compact = element.dataset.compact;
            if(compact) {
                options = quillCompact;
                console.log(options)
            }

            const placeholder = element.dataset.placeholder;
            if(placeholder) {
                options.placeholder = placeholder;
            }


            this.quill = new Quill(element,options);
            const debounce_html = debounce(function(originalInput,newValue) {
                const oldValue = originalInput.value;
                originalInput.value = removeEmptyTagsAtEnd(newValue)
                if(oldValue === "" && originalInput.value !== "") {
                    originalInput.dispatchEvent(new CustomEvent('quill-not-empty',{ bubbles: true }))
                }
            },200)

            const globalToolbarContainerId = element.dataset.toolbarContainer;
            if(globalToolbarContainerId) {
                const globalToolbarContainer = document.querySelector(globalToolbarContainerId)
                if(globalToolbarContainer) {
                    this.globalToolbarContainer = globalToolbarContainer
                    this.toolbar = this.quill.getModule('toolbar');
                    this.globalToolbarContainer.appendChild(this.toolbar.container)
                    
                    this.quill.on('selection-change', this.selectionChange.bind(this))

                    // search for existing toolbars that are not hidden
                    const shownToolbars = this.globalToolbarContainer.querySelectorAll('.ql-toolbar:not(.hidden)').length
                    if(shownToolbars > 1) {
                        this.hideToolbar(this.toolbar.container)
                    }
                }
            }

            var length = this.quill.getLength()
            var text = this.quill.getText(length - 2, 2)
            // Remove extraneous new lines
            if (text === '\n\n') {
                this.quill.deleteText(this.quill.getLength() - 2, 2)
            }

            this.quill.on('text-change', function() {
                debounce_html(originalInput,element.querySelector('.ql-editor').innerHTML);
            })

            /*quill.on(Quill.events.SCROLL_OPTIMIZE, function(t) {
                t.forEach(function(t) {
                    if(t.type === "childlist" && !t.addedNodes.length && t.removedNodes.length) {
                        const prevSibling = t.previousSibling;
                        const nextSibling = t.nextSibling;
                        if(!nextSibling && prevSibling && prevSibling.tagName === "BR" && prevSibling.className === "inline") {
                            var l = document.createElement("br");
                            l.className = "inline";
                            t.target.appendChild(l);
                        } else if( !nextSibling || !prevSibling || "BR" === prevSibling.tagName && "inline" === prevSibling.className || "BR" !== nextSibling.tagName || "inline" !== nextSibling.className || nextSibling.nextSibling ) {
                            if(nextSibling.parentNode) {
                             nextSibling.parentNode.removeChild(nextSibling)
                            }
                        }
                    }
                })
            })*/
        }
    }
}
// todo: exposing it to the window object is probably not the best solution but it gets the job done for now
window.QuillField = QuillField;