function PointEmitter() {
    return {
        points: [],
        count(value,add) {
            if(!add) {
                this.points = []
                return
            }
            this.points.push(`+${value}`)
        }
    }
}
window.PointEmitter = PointEmitter