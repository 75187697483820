// Keyboard Navigation
// - [1,2,3,4,5,6,7,8,9] toggle the corresponding answer
// - [Tab] focus next answer and the repeat button in the end
// - [ArrowUp] / [ArrowDown] focus previous / next exercise (cycle through them)
// - [Space] toggle an answer
// - [Enter] Validate exercise

function MultipleChoice() {



    return {
        validated: false,
        exercise: null,
        validation: null,
        answers: [],
        init() {
            this.exercise = window.exercises[this.$el.parentElement.dataset.index];
            this.validate = this.validate.bind(this)
            window.addEventListener('validate-multiple-choice', this.validate)
            
            if(this.exercise.shuffle_answers) {
                this.shuffle(this.exercise.multiple_choice_answers)
            }

            this.exercise.multiple_choice_answers.map((answer,index) => this.answers[index] = false)
            // this will be run  after alpine has initialized the component
            return () => {
                this.$el.scrollIntoView({behavior: 'smooth', block: 'center'});
                this.$refs.answer0.focus({preventScroll: true});

            }
        },
        shuffle(array) {
            var currentIndex = array.length, temporaryValue, randomIndex;

            // While there remain elements to shuffle...
            while (0 !== currentIndex) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;

                // And swap it with the current element.
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
            }

            return array;
        },
        selectPrev() {
            const currentAnswerIndex = parseInt(document.activeElement.dataset.index)
            let prevAnswerIndex = currentAnswerIndex -1
            if(prevAnswerIndex < 0) {
                prevAnswerIndex = this.answers.length-1;
            }
            const prevAnswer = this.$refs['answer' + prevAnswerIndex]
            prevAnswer.focus()
        },
        selectNext() {
            const currentAnswerIndex = parseInt(document.activeElement.dataset.index)
            let nextAnswerIndex = currentAnswerIndex + 1
            if(nextAnswerIndex > this.answers.length-1) {
                nextAnswerIndex = 0;
            }
            const nextAnswer = this.$refs['answer' + nextAnswerIndex]
            nextAnswer.focus()
        },
        cleanup() {
            window.removeEventListener('validate-multiple-choice', this.validate)
        },
        toggle(index) {
            if(this.validated) return;
            this.answers[index] = !this.answers[index]
        },
        validate() {
            let validation =  { correct: false, answers: [], id: this.exercise.id, points: 0}
            let valid = true
            this.exercise.multiple_choice_answers.forEach((answer,index) => {
                let correct = Boolean(answer.correct) === Boolean(this.answers[index])
                validation.answers.push({id: answer.id, validation: correct, correct: answer.correct})

                if (!correct) { valid = false }
            })
            validation.correct = valid
            validation.points = valid ? 5 : 0
            this.cleanup();
            window.dispatchEvent(new CustomEvent('validated', { detail: { validation }}))
            this.validated=true
            this.validation = validation;

        },
    }
}
window.MultipleChoice = MultipleChoice