import Quill from 'quill/core';
import Icons from "quill/ui/icons"
import Toolbar from 'quill/modules/toolbar';
import Snow from 'quill/themes/snow';

import CodeBlock from "quill/formats/code";
import Syntax from "quill/modules/syntax";
import Bold from 'quill/formats/bold';
import Italic from 'quill/formats/italic';
import Underline from 'quill/formats/underline';
import Strike from 'quill/formats/strike';
import Blockquote from 'quill/formats/blockquote';
import Link from 'quill/formats/link';
import {IndentClass} from 'quill/formats/indent'

import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import java from 'highlight.js/lib/languages/java';
import python from 'highlight.js/lib/languages/python';
import php from 'highlight.js/lib/languages/php';
import ruby from 'highlight.js/lib/languages/ruby';
import css from 'highlight.js/lib/languages/css';
import scss from 'highlight.js/lib/languages/scss';

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('java', java);
hljs.registerLanguage('python', python);
hljs.registerLanguage('php', php);
hljs.registerLanguage('ruby', ruby);
hljs.registerLanguage('css', css);
hljs.registerLanguage('scss', scss);

hljs.configure({
    languages: ['javascript', 'java', 'python', 'php', 'ruby', 'css', 'scss'],
})

import List, {ListItem} from 'quill/formats/list';
import LoadingImage from "./quill/image-upload/blots/image";
import ImageUploader from "./quill/image-upload/quill.imageUploader";
import QuillUploader from "./quill/image-upload/quillUploader";
import ImageFormat from "./quill/image-resize/ImageFormat";
import {ImageAlign} from "./quill/image-resize/modules/Toolbar";
import HighlightBlot from "./quill/HighlightBlot";
import HintBlot from "./quill/HintBlot";
import CustomHeader from "./quill/CustomHeader";
import SmartBreak, {lineBreakMatcher} from "./quill/SmartBreak";
import ImageBlot from "./quill/FigureBlot";
import CardEditableModule from "./quill/CardEditableModule";
import Formula, {FormulaBlot} from "./quill/FormulaBlot";

Icons['hint'] = "<i class=\"far fa-lightbulb\"></i>";
Icons['highlight'] = "<i class=\"far fa-highlighter\"></i>"
Icons['bold'] = "<i class=\"far fa-bold\"></i>"
Icons['italic'] = "<i class=\"far fa-italic\"></i>"
Icons['underline'] = "<i class=\"far fa-underline\"></i>"
Icons['strike'] = "<i class=\"far fa-strikethrough\"></i>"
Icons['link'] = "<i class=\"far fa-link\"></i>"
Icons['blockquote'] = "<i class=\"far fa-quote-left\"></i>"
Icons['image'] = "<i class=\"far fa-image\"></i>"
Icons['function'] = "<i class=\"far fa-function\"></i>"
Icons['list']['ordered'] = "<i class=\"far fa-list-ol\"></i>"
Icons['list']['bullet'] = "<i class=\"far fa-list-ul\"></i>"
Icons['indent']['+1'] = "<i class=\"far fa-indent\"></i>"
Icons['indent']['-1'] = "<i class=\"far fa-indent fa-flip-horizontal\"></i>"
Icons['clean'] = "<i class=\"far fa-remove-format\"></i>"
Icons['code-block'] = "<i class=\"far fa-code\"></i>"



export const quillDefaults = {
    theme: 'snow',
    formats: [
        "header", "bold", "italic", "underline", "strike", "link",
        "blockquote", "code-block", "highlight", "hint", "image", "formula",
        "list", "loadingImage"
    ],
    modules: {
        cardEditable: true,
        syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
        },
        clipboard: {
            matchers: [["BR", lineBreakMatcher]],
            matchVisual: false
        },
        toolbar: {
            container: [
                [{ 'header': [ 2, 3, 4, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                ['link'],
                ['blockquote','code-block','highlight','hint'],
                ['image','formula'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                //[{ 'indent': '-1'}, { 'indent': '+1' }],
                ['clean']
            ],
        },
        imageUploader: {
            upload: (quill,file) => {
                const uploader = new QuillUploader(quill,file);
                return uploader.upload();
            },
        },
        keyboard: {
            bindings: {
                linebreak: {
                    key: 13,
                    shiftKey: true,
                    handler: function(range) {
                        const currentLeaf = this.quill.getLeaf(range.index)[0];
                        const nextLeaf = this.quill.getLeaf(range.index + 1)[0];
                        this.quill.insertEmbed(range.index, "break", true, "user");
                        console.log("inserting break")
                        // Insert a second break if:
                        // At the end of the editor, OR next leaf has a different parent (<p>)
                        if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
                            this.quill.insertEmbed(range.index, "break", true, "user");
                            console.log("inserting second break")
                        }
                        // Now that we've inserted a line break, move the cursor forward
                        this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
                    }
                }
            }
        },
        imageResize: {
            modules: [ 'Toolbar' ],
            toolbarButtonClasses: "border bg-white border-gray-300 inline-flex items-center px-3 py-2",
            toolbarButtonActiveClasses: "text-blue-600",
            toolbarFirstButtonClasses: "-mr-px rounded-l-md",
            toolbarLastButtonClasses: "-ml-px rounded-r-md",
            toolbarClasses: "absolute left-1/2 shadow-lg transform -translate-x-1/2 -translate-y-full flex"
        }
    }
};

export const quillCompact = {...quillDefaults, ...{
        formats: [
            "bold", "italic", "underline", "strike", "link",
            "list"
        ],
        modules: {...quillDefaults.modules, ...{
                toolbar: {
                    container: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['link'],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        //[{ 'indent': '-1'}, { 'indent': '+1' }],
                        ['clean']
                    ],
                },
            }
        }
    }
}


Quill.debug("warn");

Quill.register({
    'formats/break': SmartBreak,
    'modules/toolbar': Toolbar,
    'themes/snow': Snow,
    'formats/bold': Bold,
    'formats/code-block': CodeBlock,
    'formats/italic': Italic,
    'formats/header': CustomHeader,
    'formats/underline': Underline,
    'formats/strike': Strike,
    'formats/blockquote': Blockquote,
    'formats/link': Link,
    'formats/indent': IndentClass,
    'formats/image': ImageBlot,
    'formats/formula': FormulaBlot,
    'formats/list': List,
    'formats/list-item': ListItem,
    "formats/loadingImage": LoadingImage,
    'formats/imageAlign': ImageAlign,
    'formats/highlight': HighlightBlot,
    'formats/hint': HintBlot,
    "modules/imageUploader": ImageUploader,
    'modules/imageResize': ImageFormat,
    'modules/cardEditable': CardEditableModule,
    'modules/formula': Formula,
    'modules/syntax': Syntax
});

export default Quill;