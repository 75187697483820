import { createPopper } from '@popperjs/core';

function Dropdown() {
    return {
        show: false,
        popperInstance: null,
        toggle() {
            if(this.show) {
                this.close()
            } else {
                this.open()
            }
        },
        open() {
            this.show = true
            if(!this.popperInstance) {
                this.$nextTick(() => {
                    this.popperInstance = createPopper(this.$refs.button, this.$refs.panel,{
                        placement: 'bottom-end',
                    }) });
            }
        },
        close() {
            if(!this.show) { return }
            this.show = false
            if(this.popperInstance) {
                setTimeout(() => {
                    this.popperInstance.destroy();
                    this.popperInstance = null
                } ,150)

            }
        },
        keydown(event) {
            if(event.keyCode === 32 || event.key === " ") {
                this.toggle();
                return true;
            } else if (event.keyCode === 27 || event.key === "Escape" || event.key === "Esc") {
                this.close()
                return true;
            }
            return false;
        },
        trigger: {
            ['@click']() {
                this.toggle()
            },
            ['x-on:keydown']($event) {
                this.keydown($event);
            }
        },
        container: {
            ['x-show']() {
                return this.show
            },
            ['@click.away']() {
                this.close();
            },
            ["x-transition:enter"]: "transition ease-out duration-100",
            ["x-transition:enter-start"] : "opacity-0 transform scale-95",
            ["x-transition:enter-end="] : "opacity-100 transform scale-100",
            ["x-transition:leave="] : "transition ease-in duration-75",
            ["x-transition:leave-start="] : "opacity-100 transform scale-100",
            ["x-transition:leave-end="] : "opacity-0 transform scale-95"
        }
    }
}
window.Dropdown = Dropdown;