import { styler, physics, spring, listen, pointer, value } from 'popmotion'
// Single-axis pointer
const pointerX = (x) => pointer({ x }).pipe(v => v.x)

// Keyboard Navigation:
// - [ArrowLeft] validate wrong
// - [ArrowRight] validate correct
// - [Space] switch side
function Flashcard() {
    return {
        validated: false,
        exercise: null,
        validation: null,
        // flashcard
        front: true,
        handleX: value(0),
        startListener: null,
        moveListener: null,
        stopListener: null,
        pointerTracker: null,
        cardStyler: null,
        wrongStyler: null,
        correctStyler: null,
        click: false,
        offset: 150,
        init() {
            this.exercise = window.exercises[this.$el.parentElement.dataset.index];
            this.validateCorrect = this.validateCorrect.bind(this)
            this.validateWrong = this.validateWrong.bind(this)
            this.toggle = this.toggle.bind(this)
            window.addEventListener('validate-flashcard-correct', this.validateCorrect)
            window.addEventListener('validate-flashcard-wrong', this.validateWrong)
            window.addEventListener('flip-flashcard', this.toggle)
            this.startTracking = this.startTracking.bind(this)
            this.moveTracking = this.moveTracking.bind(this)
            this.stopTracking = this.stopTracking.bind(this)
            this.cardStyler = styler(this.$refs.card)
            this.correctStyler = styler(this.$refs.correct)
            this.wrongStyler = styler(this.$refs.wrong)
            this.startListener = listen(this.$refs.card, 'mousedown touchstart').start(this.startTracking)
            this.handleX.subscribe((x) => {
                this.cardStyler.set('x',x)
                this.cardStyler.set('rotate',5 * x/this.offset)
                this.correctStyler.set('opacity',x/this.offset)
                this.wrongStyler.set('opacity',-1*x/this.offset)
            })
            return () => {
                this.$el.scrollIntoView({behavior: 'smooth', block: 'center'})
                this.$el.focus({preventScroll: true})
            }
        },
        startTracking(event) {
            event.preventDefault();
            event.stopImmediatePropagation();
            this.click = true
            if(!this.validated) {
                this.moveListener = listen(document, 'mousemove touchmove').start(this.moveTracking)
                this.pointerTracker = pointerX(this.handleX.get()).start(this.handleX)
            }
            this.stopListener = listen(document, 'mouseup touchend').start(this.stopTracking)
        },
        moveTracking(event) {
            event.preventDefault();
            event.stopImmediatePropagation();
            this.moveListener.stop();
            this.click = false;
        },
        stopTracking(event) {
            event.preventDefault();
            event.stopImmediatePropagation();
            const x = this.handleX.get()

            if (this.pointerTracker) this.pointerTracker.stop();
            if (this.stopListener) this.stopListener.stop();
            if (this.moveListener) this.moveListener.stop();

            // if there was no move (click = true) then flip the card
            if(this.click) {
                this.toggle()
                this.click = false
                return
            }

            // reduce the offset required for a card to be marked as correct / wrong on small screens
            const width  =  window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth;
            const spaceLeft = (width/2)
            if(spaceLeft < this.offset) {
                this.offset = spaceLeft-20
            }

            // mark card as right / wrong
            if(x < 0 - this.offset) {
                this.validateWrong()
            } else if(x > this.offset) {
                this.validateCorrect()
            }

            this.click = false

            // make card spring back into position
            spring({
                velocity: this.handleX.getVelocity(),
                from: x,
                stiffness: 200,
                damping: 20
            }).start(this.handleX);
        },
        validate(correct) {
            this.validated = true;
            const validation = { correct, id: this.exercise.id, points: correct ? 5 : 0 }
            this.validation = validation
            this.cleanup()
            window.dispatchEvent(new CustomEvent('validated', { detail: { validation, nextExercise: true }}))
        },
        validateWrong() {
            this.validate(false)
        },
        validateCorrect() {
            this.validate(true)
        },
        cleanup() {
            window.removeEventListener('validate-flashcard-wrong', this.validateWrong)
            window.removeEventListener('validate-flashcard-correct', this.validateCorrect)
            window.removeEventListener('flip-flashcard', this.toggle)
        },
        toggle() {
            this.front = !this.front
        },
        showFront() {
            this.front = true
        },
        showBack() {
            this.front = false
        },
        ['@click']() {
            this.toggle()
        }

    }
}
window.Flashcard = Flashcard;