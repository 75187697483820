// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "@hotwired/turbo-rails"
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

// Alpine JS Framework and Adapter to make it work on turbolinks pages
import 'alpine-turbo-drive-adapter'
import "alpinejs"

import Delta from "quill-delta"
import Emitter from "quill/core/emitter"
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Tailwind CSS
import "stylesheets/application"

// Rails JS
import Rails from "@rails/ujs";
window.Rails = Rails;

// Fontawesome
import "@fortawesome/fontawesome-pro/js/all"
// prevents the flickering of icons when using Fontawesome with turbolinks
// see: https://fontawesome.com/how-to-use/on-the-web/using-with/turbolinks
FontAwesome.config.mutateApproach = "sync"

// Active Storage
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

// Gumshoe for scrollspy / smooth scrolling
import Gumshoe from "gumshoejs"

// Tippy for Tooltips
import tippy from "tippy.js"
import "tippy.js/dist/tippy.css"

// Slim-Select for searchable selects
import Choices from "choices.js";


// Date-Fns
import { intervalToDuration } from "date-fns"
window.intervalToDuration = intervalToDuration



// Custom JS Components based on Alpine JS
import "./QuillField"
import "./SwitchField"
import "./FormRepeaterField"
import "./Dropdown"
import "./Slideover"
import "./Modal"
import "./Donutchart"
import "./Favorite"
import "./MultipleChoiceAnswer"
import "./AddExercise"
import "./ExerciseForm"
import "./RadioGroup"
import "./TextArea"
import "./Notice"

// JS Components required for playing exercises
// TODO: refactor into own JS pack so they are not loaded everywhere
import "./Counter"
import "./PointEmitter"
import "./exercises/Flashcard"
import "./exercises/MultipleChoice"
import "./exercises/Player"

document.addEventListener("turbo:load", initiate)
document.addEventListener("turbo:before-stream-render", initiate)
function initiate() {
  initiateTableOfContents()
  initiateTooltips()
  initiateSlimSelect()
}

function initiateTooltips() {
  console.log('tippy init');
  tippy("[data-tippy-content]", {
    delay: [0, 0],
  })
}

const choiceConfig = {
  addItems: true,
  duplicateItemsAllowed: false,
  removeItemButton: true,
}

function initiateSlimSelect() {
  if (document.querySelectorAll("select.js-choice").length !== 0) {
    const choices = new Choices('select.js-choice', choiceConfig);
  }
}

function initiateTableOfContents() {
  if (document.querySelectorAll(".table_of_contents").length !== 0) {
    console.log("found table of contents")
    const spy = new Gumshoe(".table_of_contents a", {
      nested: true,
      nestedClass: "active-parent",
      reflow: true,
      offset: 50,
    })
  }
}
