import Delta from 'quill-delta';
import Break from "quill/blots/break";
import Embed from "quill/blots/embed";

export const lineBreakMatcher = () => {
    let newDelta = new Delta();
    newDelta.insert({ "break": "" });
    return newDelta;
};

class SmartBreak extends Break {
    static blotName = "break"
    static tagName = "BR"

    length() {
        return 1;
    }
    value() {
        return "\n";
    }

    insertInto(parent, ref) {
        Embed.prototype.insertInto.call(this, parent, ref);
    }
}

export default SmartBreak;