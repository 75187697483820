function Slideover() {
  return {
    show: false,
    toggle() {
      if (this.show) {
        this.close()
      } else {
        this.open()
      }
    },
    open() {
      this.show = true
    },
    close() {
      if (!this.show) {
        return
      }
      this.show = false
    },
    keydown(event) {
      if (event.keyCode === 32 || event.key === " ") {
        this.toggle()
        return true
      } else if (
        event.keyCode === 27 ||
        event.key === "Escape" ||
        event.key === "Esc"
      ) {
        this.close()
        return true
      }
      return false
    },
    trigger: {
      ["@click"]() {
        this.toggle()
      },
      ["x-on:keydown.window.escape"]() {
        this.close()
      },
      ["x-on:keydown"]($event) {
        this.keydown($event)
      },
    },
    container: {
      ["x-show"]() {
        return this.show
      },
      ["@click.away"]() {
        this.close()
      },
      ["x-transition:enter"]: "transition ease-in-out duration-300",
      ["x-transition:enter-start"]: "opacity-0 ",
      ["x-transition:enter-end="]: "opacity-100",
      ["x-transition:leave="]: "transition ease-in-out duration-300",
      ["x-transition:leave-start="]: "opacity-100",
      ["x-transition:leave-end="]: "opacity-0",
    },
    slideover: {
      ["x-show"]() {
        return this.show
      },
      ["x-transition:enter"]:
        "transform transition ease-in-out duration-300 sm:duration-500",
      ["x-transition:enter-start"]: "translate-x-full",
      ["x-transition:enter-end="]: "translate-x-0",
      ["x-transition:leave="]:
        "transform transition ease-in-out duration-300 sm:duration-500",
      ["x-transition:leave-start="]: "translate-x-0",
      ["x-transition:leave-end="]: "translate-x-full",
    },
  }
}
window.Slideover = Slideover
