import {DirectUpload} from "@rails/activestorage";

export default class QuillUploader {
    constructor(quill,file) {
        this.quill = quill;
        this.file = file;
        this.quillContainer = quill.container;
        const directUploadUrl = quill.container.dataset.directUploadUrl;
        const blobUrlTemplate = quill.container.dataset.blobUrlTemplate;
        const fileInputName = quill.container.dataset.fileInputName;
        if(!directUploadUrl || !blobUrlTemplate || !fileInputName) {
            console.error("direct-upload-url, blob-url-template and file-input-name need to be specified on the quill field.");
        } else {
            this.uploadUrl = directUploadUrl;
            this.blobUrlTemplate = blobUrlTemplate;
            this.fileInputName = fileInputName;
        }

        this.uploader = new DirectUpload(this.file, this.uploadUrl, this)
    }

    upload() {
        return new Promise((resolve,reject) => {
            this.uploader.create((error, blob) => {
                if (error) {
                    return reject(error)
                } else {
                    // Add an appropriately-named hidden input to the form
                    // with a value of blob.signed_id
                    const url = this.blobUrlTemplate.replace(":signed_id",blob.signed_id).replace(":filename",blob.filename);
                    this.appendHiddenInput(blob.signed_id)
                    resolve(url)
                }
            })
        });
    }

    directUploadWillStoreFileWithXHR(request) {
        request.upload.addEventListener("progress",
            event => this.directUploadDidProgress(event))
    }

    directUploadDidProgress(event) {
        // Use event.loaded and event.total to update the progress bar
    }

    appendHiddenInput(signedId) {
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute("type", "hidden")
        hiddenField.setAttribute("value", signedId)
        hiddenField.name = this.fileInputName
        this.quillContainer.parentNode.insertBefore(hiddenField, this.quillContainer.nextSibling)
    }
}