import { BaseModule } from './BaseModule';
import Parchment from 'parchment';

let ImageAlign = new Parchment.Attributor.Class('align','align')
export { ImageAlign };

export class Toolbar extends BaseModule {
    onCreate = () => {
		// Setup Toolbar
        this.toolbar = document.createElement('div');
        this.toolbar.classList.add(...this.options.toolbarClasses.split(" "))
        this.overlay.appendChild(this.toolbar);

        // Setup Buttons
        this._defineAlignments();
        this._addToolbarButtons();
    };

	// The toolbar and its children will be destroyed when the overlay is removed
    onDestroy = () => {};

	// Nothing to update on drag because we are are positioned relative to the overlay
    onUpdate = () => {};

    _defineAlignments = () => {
        this.alignments = [
            {
                icon: "<i class=\"far fa-align-left\"></i>",
                apply: () => {
                    ImageAlign.add(this.img.closest('figure'),'left');
                },
                isApplied: () => ImageAlign.value(this.img.closest('figure')) === 'left',
            },
            {
                icon: "<i class=\"far fa-align-center\"></i>",
                apply: () => {
                    ImageAlign.add(this.img.closest('figure'),'center');
                },
                isApplied: () => ImageAlign.value(this.img.closest('figure')) === 'center',
            },
            {
                icon: "<i class=\"far fa-align-right\"></i>",
                apply: () => {
                    ImageAlign.add(this.img.closest('figure'),'right');
                },
                isApplied: () => ImageAlign.value(this.img.closest('figure')) === 'right',
            },
            {
                icon: "<i class=\"far fa-trash-alt\"></i>",
                apply: () => {
                    this.img.closest('figure').remove();
                    this.hide();
                },
                isApplied: () => false,
            },
        ];
    };

    _addToolbarButtons = () => {
		const buttons = [];
		this.alignments.forEach((alignment, idx) => {
			const button = document.createElement('span');
			buttons.push(button);
			button.innerHTML = alignment.icon;
			button.addEventListener('click', () => {
			    // deselect all buttons
				buttons.forEach(button => button.classList.remove(...this.options.toolbarButtonActiveClasses.split(" ")));
				if (alignment.isApplied()) {
					ImageAlign.remove(this.img.closest('figure'));
				} else {
					this._selectButton(button);
					alignment.apply();
				}
				// image may change position; redraw drag handles
				this.requestUpdate();
			});
            button.classList.add(...this.options.toolbarButtonClasses.split(" "))
            if(idx === this.alignments.length-1) {
                button.classList.add(...this.options.toolbarLastButtonClasses.split(" "))
            }
            if(idx === 0) {
                button.classList.add(...this.options.toolbarFirstButtonClasses.split(" "))
            }

			if (alignment.isApplied()) {
			    // select button if previously applied
				this._selectButton(button);
			}
			this.toolbar.appendChild(button);
		});
    };

    _selectButton = (button) => {
        button.classList.add(...this.options.toolbarButtonActiveClasses.split(" "))
    };

}

function stringToClassList(classList) {
    return classList.split(" ");
}