function ExerciseForm() {
    return {
        turboFrame: null,
        init() {
            document.addEventListener('turbo:submit-end',this.submitEnd.bind(this))
            this.turboFrame = this.$el.closest('turbo-frame')
        },
        removeExerciseForm() {
            this.turboFrame.remove();
        },
        submitEnd(event) {
            const formSubmission = event.detail.formSubmission
            if(formSubmission.delegate.element === this.turboFrame) {
                // remove the form to create the exercise when it was created successfully
                if(formSubmission.result.fetchResponse.succeeded) {
                    if(this.turboFrame.dataset.new === "true") {
                        //this.turboFrame.remove()
                    }
                }
            }
        }
    }
}
window.ExerciseForm = ExerciseForm