import Embed from "quill/blots/embed"
import Quill from "quill"
import Module from "quill/core/module"
import katex from "katex";

class FormulaBlot extends Embed {
    static create(value) {
        let node = super.create(value);
        if (typeof value === 'string') {
            katex.render(value, node, {
                throwOnError: false,
                errorColor: '#f00'
            });
            node.setAttribute('data-value', value);
        }
        return node;
    }

    static value(domNode) {
        return domNode.getAttribute('data-value');
    }
}
FormulaBlot.blotName = 'formula';
FormulaBlot.className = 'ql-formula';
FormulaBlot.tagName = 'SPAN';


class Formula extends Module {
    static register() {
        Quill.register(FormulaBlot, true);
    }

    constructor() {
        super();
    }
}

export { FormulaBlot, Formula as default };
